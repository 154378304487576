import React from 'react';
import { HTMLContent } from '../components/Content';
import LinkComponent from '../components/LinkComponent';

const TextAndImage = ({ block }) => {
  const {
    image,
    image_position,
    content,
    isCta,
    buttonText,
    linkObject,
  } = block;
  const ImageBlock = () => (
    <div className='column is-4'>
      <img src={image} className='services-images hover-zoom' alt='' />
    </div>
  );
  const TextBlock = () => (
    <div className='column is-6 vertically-centered is-flex'>
      <hr className='hr hr-black' />
      <HTMLContent content={content} />
      {isCta ? (
        <LinkComponent
          linkObject={linkObject}
          children={buttonText}
          className='btn btn-main text-image-cta'
        />
      ) : null}
    </div>
  );

  const renderPosition = () => {
    if (image_position === 'left') {
      return (
        <>
          <ImageBlock />
          <TextBlock />
        </>
      );
    }
    return (
      <>
        <TextBlock />
        <ImageBlock />
      </>
    );
  };

  return (
    <div className='columns text-image-block'>
      <div className='column is-1' />
      {renderPosition()}
      <div className='column is-1' />
    </div>
  );
};

export default TextAndImage;
